import React, { useState, useEffect } from 'react';
import { Container, Button, Typography, Box, useTheme } from '@mui/material';
import SetHeaderData from '../components/SetHeaderData';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const HolidayAccommodation = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);
  const [imageList, setImageList] = useState([]);


  useEffect(() => {
    const loadContent = async () => {
      const response_language = await import(`../data/holiday_accommodation/${language}.json`);
      const responseimagelist = await import(`../data/holiday_accommodation/image_list.json`);
      setContent(response_language);
      setImageList(responseimagelist.images)
    };
    loadContent();
  }, [language]);

  if (!content || !imageList) return <Typography>Loading...</Typography>;

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <Container>

      <SetHeaderData content={content} />

      <Box sx={{ my: 4 }}>

        <Typography variant="h4" component="h1" gutterBottom sx={{ my: 2 }}>{content.title}</Typography>

        <Box
            sx={{
              my: 4,
              p: 2,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
            }}>
          <Typography variant="body1" component="p" gutterBottom sx={{ my: 2 }}>{content.paragraph1}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ my: 2 }}>{content.paragraph2}</Typography>
        </Box>

        <Box
          sx={{
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ my: 2 }}>
            {content.discounts.title}
          </Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ my: 2 }}>
            {content.discounts.content}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
            <Button component={Link} to={content.discounts.massage_types.link} variant="contained"
              sx={{ textDecoration: 'none', color: theme.palette.divider }}>
                {content.discounts.massage_types.description}
            </Button>
            <Button component={Link} to={content.discounts.body_treatments.link} variant="contained"
              sx={{ textDecoration: 'none', color: theme.palette.divider }}>
                {content.discounts.body_treatments.description}
            </Button>
            <Button component={Link} to={content.discounts.face_treatment.link} variant="contained" 
              sx={{ textDecoration: 'none', color: theme.palette.divider }}>
                {content.discounts.face_treatment.description}
            </Button>
            <Button component={Link} to={content.discounts.hair_spa.link} variant="contained"
              sx={{ textDecoration: 'none', color: theme.palette.divider }}>
                {content.discounts.hair_spa.description}
            </Button>
          </Box>

        </Box>

        <Box
            sx={{
              my: 4,
              p: 2,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
            }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ my: 2 }}>{content.book_now.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ my: 2 }}>{content.book_now.paragraph}</Typography>

          <Box sx={{ mt: 4 }}>
            <Button component={Link} variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} 
              to={content.book_now.goto.airbnb.link} target="_blank">
                {content.book_now.goto.airbnb.description}
            </Button>
            <Button component={Link} variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} 
              to={content.book_now.goto.fewo.link} target="_blank">
                {content.book_now.goto.fewo.description}
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>

          <Typography variant="h4" component="h2" gutterBottom sx={{ my: 2 }}>{content.apartment.title}</Typography>
          
          <Slider {...sliderSettings}>
            {imageList.map((imageSrc, index) => (
              <Box key={index}>
                <Box
                  component="img"
                  sx={{
                    maxWidth: 1254,
                    maxHeight: 672,
                    margin: '0 auto',
                    display: 'block',
                    borderRadius: '10px'
                  }}
                  alt={`Slide ${index + 1}`}
                  src={imageSrc}
                />
              </Box>
            ))}
          </Slider>


          <Typography variant="body" component="p" gutterBottom sx={{ my: 2 }}>{content.apartment.content1}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ my: 2 }}>{content.apartment.content2}</Typography>          
          <Typography variant="body" component="p" gutterBottom sx={{ my: 2 }}>{content.apartment.content3}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ my: 2 }}>{content.apartment.content4}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ my: 2 }}>{content.apartment.content5}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ my: 2 }}>{content.apartment.content6}</Typography>
        
        </Box>

        <Box
          sx={{
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ my: 2 }}>
            {content.activities.title}
          </Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ my: 2 }}>
            {content.activities.content1}
          </Typography>

          <Typography variant="body1" component="p" gutterBottom sx={{ my: 2 }}>
            {content.activities.content2}
          </Typography>

          <Typography variant="body1" component="p" gutterBottom sx={{ my: 2 }}>
            {content.activities.content3}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
            <Button variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} href={content.activities.bar1.link}>{content.activities.bar1.description}</Button>
            <Button variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} href={content.activities.bar2.link}>{content.activities.bar2.description}</Button>
            <Button variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} href={content.activities.restaurant1.link}>{content.activities.restaurant1.description}</Button>
            <Button variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} href={content.activities.restaurant2.link}>{content.activities.restaurant2.description}</Button>
            <Button variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} href={content.activities.restaurant3.link}>{content.activities.restaurant3.description}</Button>
          </Box>
        </Box>
      </Box>
  </Container>
  )
};

export default HolidayAccommodation;
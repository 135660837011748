import React, { useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Favicon from "react-favicon";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import theme from './theme';
import Home from './pages/Home';
import About from './pages/About';
import Location from './pages/Location';
import Header from './components/Header';
import MassageTypes from './pages/MassageTypes';
import BodyTreatments from './pages/BodyTreatments';
import FaceTreatment from './pages/FaceTreatment';
import HairSpa from './pages/HairSpa';
import SpecialPromotions from './pages/SpecialPromotions';
import JapanesHairStraightening from './pages/JapanesHairStraightening';
import Wedding from './pages/Wedding';
import HairdresserPrices from './pages/HairdresserPrices';
import MakeUp from './pages/MakeUp';
import BookNow from './pages/BookNow';
import HolidayAccommodation from './pages/HolidayAccommodation';
import BookHolidaysForm from './pages/BookHolidaysForm';
import Footer from './components/Footer';
import Privacy from './pages/Privacy';
import Jobs from './pages/Jobs';
import VirtualAssistant from './pages/VirtualAssistant';
import Shop from './pages/Shop';
import Checkout from './pages/Checkout';
import Ad from './pages/Ad'
import CookieConsent from './components/CookieConsent';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-JMK3F834D5');
ReactGA.initialize('AW-11157333271');

function App() {
  const [language, setLanguage] = useState('de');

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Favicon url="favicon.ico" />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: theme.palette.background.default }}>
        <Router>
          <Header setLanguage={setLanguage} language={language}/>
          <Container sx={{ flex: 1 }}>
            <Routes>
              <Route path="/" element={<Navigate replace to="/home" />} />
              <Route path="/home" element={<Home language={language} />} />
              <Route path="/massage_types" element={<MassageTypes language={language} />} />
              <Route path="/body_treatments" element={<BodyTreatments language={language} />} />
              <Route path="/face_treatment" element={<FaceTreatment language={language} />} />
              <Route path="/hair_spa" element={<HairSpa language={language} />} />
              <Route path="/special_promotions" element={<SpecialPromotions language={language} />} />
              <Route path="/japanese_hair_straightening" element={<JapanesHairStraightening language={language} />} />
              <Route path="/wedding" element={<Wedding language={language} />} />
              <Route path="/hairdresser_prices" element={<HairdresserPrices language={language} />} />
              <Route path="/make_up" element={<MakeUp language={language} />} />
              <Route path="/book_now" element={<BookNow language={language} />} />
              <Route path="/location" element={<Location language={language} />} />
              <Route path="/about" element={<About language={language} />} />
              <Route path="/privacy" element={<Privacy language={language} />} />
              <Route path="/jobs" element={<Jobs language={language} />} />
              <Route path="/holiday_accommodation" element={<HolidayAccommodation language={language} />} />
              <Route path="/book_holidays_form" element={<BookHolidaysForm language={language} />} />
              <Route path="/virtual_assistant" element={<VirtualAssistant language={language} />} />
              <Route path="/shop" element={<Shop language={language} />} />
              <Route path="/checkout" element={<Checkout language={language} />} />
              <Route path="/ad" element={<Ad setLanguage={setLanguage}/>} />
            </Routes>
          </Container>
          <CookieConsent language={language}/>
          <Footer language={language}/>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default App;

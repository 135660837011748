import { createTheme } from '@mui/material/styles';
import { amber } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#263a33',
    },
    secondary: {
      main: amber[500],
    },
    background: {
      banner: '#102820',
      default: '#40534d',
      paper: '#263a33',
      chatInput: '#1c1c1c', // Anthracite background for chat input
      chatResponse: '#333333', // Slightly lighter anthracite for assistant responses
    },
    text: {
      primary: '#ffffff',
      secondary: '#aaaaaa',
      link: '#E6B560', // Custom link color for answers
    },
    divider: '#E6B560',
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    blockquote: {
      textAlign: 'center',
      fontStyle: 'italic',
      padding: '24px',
      margin: '32px auto',
      maxWidth: '600px',
      color: '#dddddd',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderLeft: '4px solid #E6B560',
      borderRadius: '8px',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: () => ({
          backgroundColor: '#2d3a38',
          color: '#ffffff',
          border: `1px solid ${amber[500]}`,
          borderRadius: '8px',
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            backgroundColor: '#1c1c1c',
            color: '#ffffff',
            borderRadius: '8px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: amber[500],
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e6b560',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e6b560',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#E6B560', // Amber-like color for links
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline', // Underline on hover
          },
        },
      },
    },
    blinkingText: {
      animation: 'blink 1.5s infinite', // Use defined animation
      '@keyframes blink': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0.5 },
        '100%': { opacity: 1 },
      },
      color: '#aaaaaa', // Secondary text color for blinking text
    },
  },
});

export default theme;

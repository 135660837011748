import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, useTheme } from '@mui/material';
import SetHeaderData from '../components/SetHeaderData';
import { useNavigate } from 'react-router-dom';
import Checkout from './Checkout'

const Shop = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);
  const [shoppingCart, setShoppingCart] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/shop/${language}.json`);
      setContent(response);
    };
    loadContent();
  }, [language]);

  const addArticle = (article) => {
    setShoppingCart((prevCart) => [...prevCart, article]);
  };

  const calculateTotal = () => {
    return shoppingCart.reduce((total, article) => total + article.price, 0).toFixed(2);
  };

  if (!content) return <Typography>Loading...</Typography>;

  return (
    <Container>
      <SetHeaderData content={content} />

      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ my: 2 }}>
          {content.title}
        </Typography>

        {content.articles.map((article, index) => (
          <Box
            key={index}
            sx={{
              my: 4,
              p: 2,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Typography variant="h5" component="h1" sx={{ my: 2 }}>
              {article.title}
            </Typography>
            <Typography variant="body1" component="p" sx={{ my: 2 }}>
              {article.description}
            </Typography>
            <Typography variant="body1" component="p" sx={{ my: 2 }}>
              {content.buy_now} {article.price} CHF
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => addArticle(article)} // Pass the article object directly
              sx={{ mt: 2 }}
              >Add to Cart
            </Button>
          </Box>
        ))}

        <Box sx={{ my: 4 }}>
          <Typography variant="h5" component="h1" sx={{ my: 2 }}>
            Shopping Cart
          </Typography>
          {shoppingCart.map((item, index) => (
            <Typography key={index} variant="body1" sx={{ my: 1 }}>
              {item.title} - {item.price} CHF
            </Typography>
          ))}
          <Typography variant="h6" sx={{ my: 2 }}>
            Total: {calculateTotal()} CHF
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() =>
              navigate('/checkout', { state: { shoppingCart } })
            }
          >Checkout
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Shop;
